







































import { Observer } from 'mobx-vue'
import { Component, Vue, Inject } from 'vue-property-decorator'
import { walletStore } from '@/stores/wallet-store'

@Observer
@Component({
  components: {
    staking: () => import('../../staking/pages/staking.vue'),
    'nft-minting': () => import('./nft-minting.vue'),
    'staking-design-system': () => import('../../staking/pages/staking-design-system.vue'),
  },
})
export default class Vault extends Vue {
  @Inject() providers
  walletStore = walletStore
  tab = 0

  destroyed() {
    //
  }
}
